//Blog Archive Page
import React from "react"
import { graphql, Link, navigate } from "gatsby"
import Img from "gatsby-image"
import ReactPaginate from "react-paginate"
// import { div, Box, Heading, Text, div, Button } from "@chakra-ui/core"
import Layout from "../components/layout"
import { normalizePath } from "../utils/get-url-path"

export default ({ data, pageContext }) => (
  <Layout>
    <div>
      {data.allWpPost.nodes.map(page => (
        <div key={page.link}>
          <Link to={normalizePath(page.uri)}>
            <div>
              <div>
                <div>
                  {!!page.featuredImage &&
                    !!page.featuredImage.remoteFile &&
                    !!page.featuredImage.remoteFile.childImageSharp && (
                      <Img
                        fluid={
                          page.featuredImage.remoteFile.childImageSharp.fluid
                        }
                      />
                    )}
                </div>
                <div>
                  <h3>
                    {page.title}
                  </h3>
                  {!!page.author && !!page.author.name && (
                    <h3>
                      Author: {page.author.name}
                    </h3>
                  )}

                  <div>
                    <p dangerouslySetInnerHTML={{ __html: page.excerpt }} />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>

    {pageContext && pageContext.totalPages > 1 && (
      <div mt={10}>
        <ReactPaginate
          previousLabel={
            pageContext?.page !== 1 && <div>Previous page</div>
          }
          nextLabel={
            pageContext?.totalPages !== pageContext.page && (
              <div>Next page</div>
            )
          }
          onPageChange={({ selected }) => {
            const page = selected + 1
            const path = page === 1 ? `/blog/` : `/blog/${page}/`
            navigate(path)
          }}
          disableInitialCallback
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageContext.totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          initialPage={pageContext.page - 1}
        />
      </div>
    )}
  </Layout>
)

export const query = graphql`
  fragment Thumbnail on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
  query HomePage($offset: Int!, $perPage: Int!) {
    allWpPost(
      limit: $perPage
      skip: $offset
      filter: { nodeType: { in: ["Post", "Page", "Alot"] } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        uri
        title
        featuredImage {
          remoteFile {
            ...Thumbnail
          }
        }
      }
    }
  }
`
